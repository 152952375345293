import React from 'react';
import '../../style/App.css';


function AjAj(props) {
  return(
    <div className="error">
      <h1 >AJ AJ..</h1>
      <h3>Hadde du lov til dette?</h3>
    </div>
  );
}

export default AjAj;
