import React from 'react';
import '../style/App.css';

function Footer(props) {
  return(
    <div className="footer-container">
      <div className="paragraph-light-forside-mail">Jakob Follesø Egeland | 2021</div>
    </div>

  );
}

export default Footer;
